import styled from "styled-components";
import setaIcon from "./ico/seta.svg";
import setaBaixo from "./ico/seta_baixo_cinza.svg";

// Componente principal que agrupa o filtro
export const FiltroRepasses = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: 460px) {
		flex-direction: column;
		align-items: start;
		gap: 1rem;
	}
`;

// Botão reutilizável para gerar relatório e visão geral
export const BaseButton = styled.button`
	background-color: transparent;
	color: #5d7aba;
	cursor: pointer;
	transition: color 200ms ease-in-out;
	font-size: 1rem;
	font-weight: normal;

	&:hover {
		color: #778bb6;
	}

  	${(props) => props.active && `text-decoration: underline;`}
`;

// Botão reutilizável para outras opções
export const Button = styled.button`
	font-weight: 400;
	font-size: 12px;
	text-align: center;
	color: ${(props) => (props.active ? "#FFF" : "#FD541E")};
	border-radius: 4px;
	border: 1px solid #fd541e;
	background-color: ${(props) => (props.active ? "#FD541E" : "#fff")};
	transition: color 200ms ease-in-out, background-color 200ms ease-in-out,
		border 200ms ease-in-out;
	padding: 5px 10px;

	&:hover {
		background-color: #fd541e;
		border-color: #fd541e;
		color: #fff;
		transition: 0.3s ease-in-out;
	}
`;

// Grupo de botões
export const FiltroButtons = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 10px;

	@media screen and (max-width: 346px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 2rem;
	}

	@media screen and (max-width: 320px) {
		column-gap: 1rem;
	}
`;

// Container principal (Informações gerais sobre os repasses federais)
export const Repasses = styled.section`
	width: 100%;
	padding: 20px;
	border-radius: 8px;
	background: #bad6db;
	margin: 20px 0px 0 0;
	display: flex;
	justify-content: space-between;
`;

// Moacir David - criei esse componente
// Acho mais maleável trabalhar com eles de maneira individual
export const ContentWrapper = styled.section`
	width: 100%;
	height: 100%;
	padding: 20px;
	border-radius: 8px;
	margin: 20px 0px 0 0;
	display: flex;
	justify-content: space-between;
	background-color: #9ae0ec;
	position: relative;

	@media screen and (max-width: 480px) {
		height: 80vh;
		flex-direction: column;
	}
`;

Repasses.ranking = styled.div`
	width: 100%;
	border-radius: 8px;
	background: #bad6db;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	@media screen and (max-width: 1200px) {
		border: 2px solid rebeccapurple;
	}
`;

export const RepassesAside = styled.div`
	background-color: #ffffff;
	border-radius: 8px;
	padding: 28px 22px;
	width: 255px;
	/* height: 611px; */
	height: 430px;
	z-index: 2;

	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (max-width: 480px) {
		height: auto;
		width: 200px;
		height: 120px;
		overflow-y: hidden;
		padding: 20px 14px;
	}

	@media screen and (max-width: 360px) {
		width: 160px;
		height: 100px;
		padding: 10px 14px;
	}
`;

export const AsideInfo = (Repasses.asideInfo = styled.div`
	color: #373f47;
	display: flex;
	flex-direction: column;
	align-items: baseline;
`);

export const AsideBox = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	padding-bottom: 14px;

	@media screen and (max-width: 360px) {
		font-size: 14px;
	}
`;

export const AsideTitle = styled.p`
	font-size: 22px;
	font-weight: 500;
	padding-bottom: 16px;

	@media screen and (max-width: 360px) {
		font-size: 18px;
	}
`;

export const AsideLista = styled.ul`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 20px;

	@media screen and (max-width: 480px) {
		display: none;
	}
`;

export const AsideListaItem = styled.li`
	border-radius: 8px;
	background-color: #e2f1f8;
	height: 83px;
	width: 225px;
	padding: 8px;

	display: flex;
	align-items: center;
	gap: 9px;

	figcaption {
		color: #000000;

		span {
			font-weight: 500;
			font-size: 18px;
		}

		p {
			font-size: 12px;
			width: 140px;
		}

		@media screen and (max-width: 480px) {
			display: none;
		}
	}
`;

export const AsideBottom = styled.div`
	color: #184479;
	font-size: 14px;
	font-weight: 500;
	padding-top: 16px;

	@media screen and (max-width: 480px) {
		display: none;
	}
`;

export const AsideRightComponentes = styled.div`
	display: flex;
	align-items: flex-end;
	justify-self: flex-end;
	flex-direction: column;
	justify-content: space-between;
	height: 611px;
	position: relative;

	@media screen and (max-width: 480px) {
		height: 100%;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
`;

export const BotaoSelectAnoWrapper = styled.div`
	display: flex;
	justify-self: flex-end;
	height: 40px;

	select {
		background-color: #ffffff;
		border: none;
		border-radius: 5px;
		color: #1b4677;
		cursor: pointer;
		font-size: 14px;
		padding: 8px 28px 8px 18px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		transition: border 300ms ease-in-out;
		background: url(${setaIcon}) no-repeat calc(100% - 8px) #ffffff;
		z-index: 2;
	}

	@media screen and (max-width: 480px) {
		align-self: flex-end;
		right: 0;
		top: -108px;
		position: absolute;
	}

	@media screen and (max-width: 380px) {
		top: -104px;
	}

	@media screen and (max-width: 360px) {
		top: -88px;
	}
`;

export const AsideRightBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
	gap: 24px;
	width: 100%;

	@media screen and(max-width: 480px) {
		width: 100%;
	}
`;

export const CongressistasRanking = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 2;
	background-color: #ffffff;
	border-radius: 8px;
	height: 180px;
	width: 612px;
	padding: 10px 15px;

	button {
		font-weight: 400;
		font-size: 12px;
		text-align: center;
		background-color: #fd541e;
		border-radius: 4px;
		color: #fff;
		transition: color 200ms ease-in-out, background-color 200ms ease-in-out,
		border 200ms ease-in-out;
		padding: 5px 10px;
		width: 150px;

		&:hover {
		background-color: #e64b03;
		}
	}

	@media screen and (max-width: 480px) {
		width: 330px;
		position: absolute;
		bottom: 10px;
		left: 0;
	}

	@media screen and (max-width: 432px) {
		width: 100%;
		position: absolute;
		bottom: 10px;
		left: 0;
		padding: 20px 15px;
	}
`;

export const CongressistasTitulo = styled.p`
	color: #1b4677;
	font-weight: 600;
	padding: 0 0 15px 0;
`;

export const CongressistasLista = styled.ul`
	display: flex;
	align-items: center;
	gap: 28px;
`;

export const CongressistasListaItem = styled.li`
  	display: flex;
	gap: 10px;

	figure {
		width: 60px;
		height: 60px;
		margin: 0 auto 18px;
		border-radius: 50%;
		border: 1px solid #9dc9ea94;
		overflow: hidden;
		background-color: #f6f6f6ed;

		img {
			width: 100%;
		}
 	}

  	figureCaption {
		p:first-child {
		background-color: #4040f2;
		border-radius: 3px;
		color: #ffffff;
		font-size: 12px;
		font-weight: 500;
		width: 28px;
		text-align: center;
		margin-bottom: 0.15rem;
		}

		strong {
		color: #1b4677;
		font-weight: 400;
		font-size: 14px;
		margin-bottom: 0.15rem;
		}

		p:last-child {
		color: #1b4677;
		font-size: 10px;
		}
  	}
`;

// Container com os gráficos de Linha e Barra
export const Charts = styled.section`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	transition: opacity 300ms ease-in-out;

	@media screen and (max-width: 953px) {
		flex-direction: column;
	}
`;

Charts.box = styled.div`
	width: calc(50% - 10px);
	margin-bottom: 20px;
	border: 1px solid #eaecee;
	border-radius: 8px;
	background: #fff;
	margin: 20px 0;

	@media screen and (max-width: 1089px) {
		width: calc(50% - 5px);
		margin: 20px 0 0 0;
	}

	@media screen and (max-width: 953px) {
		width: 100%;
		margin-top: 20px;
	}

	@media screen and (max-width: 422px) {
		margin: 10px 0 20px 0;
	}
`;

Charts.header = styled.div`
	header {
		width: 100%;
		margin-bottom: 12px;
		padding: 20px 0 0 20px;
		display: flex;
		font-size: 16px;
		justify-content: space-between;

		h3 {
		font-weight: 500;
		font-size: 18px;
		color: #373f47;
		display: block;
		padding-right: 10px;
		}
	}
`;

Charts.echartsBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	/* padding: 0 8px; */
`;

// Container com mapa de mesorregião
export const Mesorregiao = styled.section`
	width: 100%;
	padding: 20px 0 20px 20px;
	margin-bottom: 20px;
	border: 1px solid #eaecee;
	border-radius: 8px;
	background: #fff;
	margin: 20px 0px 0 0;

	header {
		width: 100%;
		margin-bottom: 12px;
		padding: 10px 20px 10px 0px;
		display: flex;
		font-size: 16px;
		justify-content: space-between;

		h3 {
		font-weight: 500;
		font-size: 18px;
		color: #373f47;
		display: block;
		padding-right: 10px;
		}
	}
`;

Mesorregiao.container = styled.div`
	display: flex;
	align-items: centers;
	justify-content: space-between;
	gap: 5px;

	@media screen and (max-width: 1100px) {
		flex-wrap: wrap;
		border: 6px solid blue;
		width: 100%;
	}
`;

Mesorregiao.box = styled.div`
	display: flex;
	align-items: center;
	gap: 45px;
	width: 50%;

	ul {
		display: flex;
		flex-direction: column;
		gap: 10px;

		li {
		span {
			color: #181894;
			font-weight: 400;
		}

		p {
			font-size: 12px;
		}
		}
	}
`;

// Box com o gráfico de donut
Mesorregiao.donutChart = styled.div`
	width: 50%;

	header {
		width: 100%;
		margin-bottom: 12px;
		padding: 10px 20px 10px 0px;
		display: flex;
		font-size: 16px;
		justify-content: space-between;

		h3 {
		font-weight: 500;
		font-size: 18px;
		color: #373f47;
		display: block;
		padding-right: 10px;
		}
	}
`;

export const RepassesFooter = styled.footer`
	p {
		color: #717d8a;
		font-size: 14px;
		padding-top: 20px;
	}
`;

export const MapaWrapper = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 100vw;
	margin: auto;
	height: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	overflow: hidden;
`;

export const MenuSeletoFiltro = styled.select`
	width: 100%;
	padding: 9px 13px;
	outline: none;
	cursor: pointer;
	font-size: 14px;
	appearance: none;
	border-radius: 6px;
	padding-right: 30px;
	border: 1px solid #d6dade;
	background-color: transparent;
	color: black;
	background: url(${setaBaixo}) no-repeat right 10px center;
	height: 40px;
	position: relative;
`;


export const AutoCompleteSelectBurcarMunicipioWrapper = styled.div`
	li {
		background-color: #fff;
	}
`;