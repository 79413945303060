import React, { useRef, useState } from "react";

//Style
import Style from "./index.module.scss";
import { Charts } from "../../../routes/repassesFederais/styles";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Mousewheel, Keyboard } from "swiper";

// Se necessário, você pode importar outros módulos da seguinte forma:
import { Navigation, Pagination } from "swiper";

//lib
import ReactECharts from "echarts-for-react";

//Imagens
import IconeLocalizacao from "../comparativoEmendas/img/icon-localizacao.svg";
import IconePopulacao from "./img/icon-populacao.svg";
import IconeCifrao from "./img/icon-cifrao.svg";

const ComparativoEmendas = () => {
    const lineChartOption = {
        tooltip: {
            trigger: "axis",
        },
        xAxis: {
            type: "category",
            data: ["JAN", "MAR", "JUN", "SET"],
            boundaryGap: false,
            axisLine: {
                lineStyle: {
                    color: "#EAECEE",
                    width: 1,
                },
            },
            axisLabel: {
                color: "#7C828A",
                fontSize: 12,
            },
        },
        yAxis: {
            type: "value",
            min: 0,
            max: 15,
            interval: 5,
        },
        series: [
            {
                name: "Linha 1",
                data: [0, 4, 6, 12],
                type: "line",
                areaStyle: {
                    color: {
                        type: "linear",
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: "rgba(0, 102, 255, 0.5)",
                            },
                            {
                                offset: 1,
                                color: "rgba(0, 102, 255, 0)",
                            },
                        ],
                        global: false,
                    },
                },
                smooth: false,
                lineStyle: {
                    color: "#4040F2",
                    width: 2,
                },
                symbol: "circle",
                symbolSize: 8,
                // Definindo a cor do símbolo para a linha 1
                itemStyle: {
                    color: "#4040F2",
                },
            },
            {
                name: "Linha 2",
                data: [0, 2, 3, 6],
                type: "line",
                areaStyle: {
                    color: {
                        type: "linear",
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: "rgba(251, 83, 3, 0.5)",
                            },
                            {
                                offset: 1,
                                color: "rgba(251, 83, 3, 0)",
                            },
                        ],
                        global: false,
                    },
                },
                smooth: false,
                lineStyle: {
                    color: "#FB5303",
                    width: 2,
                },
                symbol: "circle",
                symbolSize: 8,
                // Definindo a cor do símbolo para a linha 2
                itemStyle: {
                    color: "#FB5303",
                },
            },
        ],
    };

    //Gráfico de barras
    const barChartOption = {
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "shadow",
            },
        },
        legend: {
            data: ["Goiânia", "Anápolis"],
            top: "-5px",
            right: "45px",
            textStyle: {
                color: "#7C828A",
                fontSize: 12,
            },
        },
        grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            top: "30px",
            containLabel: true,
        },
        xAxis: {
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#EAECEE",
                    width: 1,
                },
            },
            axisLabel: {
                show: false,
                color: "#7C828A",
                fontSize: 12,
            },
            splitLine: {
                show: false,
            },
        },
        yAxis: {
            type: "category",
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#EAECEE",
                    width: 1,
                },
            },
            axisLabel: {
                formatter: (value) => value,
                color: "#7C828A",
                fontSize: 12,
            },
            data: [
                "Individuais",
                "Bancada",
                "Comissão",
                "Relator",
                "Populares",
                "Impositivas",
            ],
        },
        series: [
            {
                name: "Goiânia",
                type: "bar",
                data: [182, 234, 290, 104, 131, 230],
                itemStyle: {
                    color: "#4141F2",
                },
                label: {
                    show: true,
                    position: "right",
                    formatter: (params) => `R$ ${params.data}`, // Formatação dos valores
                    color: "#7C828A",
                },
            },
            {
                name: "Anápolis",
                type: "bar",
                data: [193, 234, 310, 121, 134, 231],
                itemStyle: {
                    color: "#FB5303",
                },
                label: {
                    show: false,
                },
            },
        ],
    };
    const barChartCarrosel = {
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "shadow",
            },
        },
        dataset: {
            source: [
                ["score", "amount", "product"],
                [40.7, 380, "Populares"],
                [90.1, 490, "Impositivas"],
                [60.5, 500, "Relator"],
                [80.2, 365, "Comissão"],
                [54.1, 400, "Bancada"],
                [70.3, 300, "Individuais"],
            ],
        },
        grid: {
            left: "5%",
            top: "5%",
            containLabel: true,
        },
        xAxis: {
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#EAECEE",
                    width: 1,
                },
            },
            axisLabel: {
                show: false,
                color: "#7C828A",
                fontSize: 12,
            },
            splitLine: {
                show: false,
            },
        },
        yAxis: {
            type: "category",
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#EAECEE",
                    width: 1,
                },
            },
            axisLabel: {
                formatter: (value) => value,
                color: "#7C828A",
                fontSize: 12,
            },
        },
        series: [
            {
                type: "bar",
                encode: {
                    x: "amount",
                    y: "product",
                },
                label: {
                    show: true,
                    position: "right",
                    formatter: (params) => `R$ ${params.data}`, // Formatação dos valores
                    color: "#7C828A",
                },
                itemStyle: {
                    color: (params) => {
                        const colorList = [
                            "#6E6EF7",
                            "#4040F2",
                            "#3333D1",
                            "#181894",
                            "#0D0D54",
                        ];
                        return colorList[params.dataIndex % colorList.length];
                    },
                },
                barWidth: "50%", // Ajuste a largura das barras aqui
            },
        ],
    };

    return (
        <>
            <section className={Style.boxMapa}>
                <div className={Style.boxMapaEsquerdo}>
                    <div className={Style.boxMeuMunicipio}>
                        <div className={Style.meuMunicipioTitulo}>
                            <img
                                src={IconeLocalizacao}
                                alt="Ícone de localização"
                            />
                            <p>meu município</p>
                        </div>
                        <p className={Style.municipioSelecionado}>
                            Goiânia - GO
                        </p>
                    </div>

                    <div className={Style.boxInformacoesEsquerdo}>
                        <div className={Style.cardInformacoesEsquerdo}>
                            <div className={Style.conjuntoCards}>
                                <div className={Style.linha1}>
                                    <div
                                        className={`${Style.cardPopulacao} ${Style.card}`}
                                    >
                                        <img
                                            width="60px"
                                            src={IconePopulacao}
                                            alt="Ícone de população"
                                        />
                                        <div>
                                            <p className={Style.valorPopulacao}>
                                                1.437.366
                                            </p>
                                            <p className={Style.nomePopulacao}>
                                                população
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={`${Style.cardPopulacao} ${Style.card}`}
                                    >
                                        <img
                                            width="35px"
                                            src={IconeCifrao}
                                            alt="Ícone de população"
                                        />
                                        <div>
                                            <p className={Style.valorPopulacao}>
                                                1.437.366
                                            </p>
                                            <p className={Style.nomePopulacao}>
                                                emendas pagas
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className={Style.linha2}>
                                    <div
                                        className={`${Style.cardPopulacao} ${Style.card}`}
                                    >
                                        <img
                                            width="35px"
                                            src={IconeCifrao}
                                            alt="Ícone de população"
                                        />
                                        <div>
                                            <p className={Style.valorPopulacao}>
                                                1.437.366
                                            </p>
                                            <p className={Style.nomePopulacao}>
                                                valor empenhado
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={`${Style.cardPopulacao} ${Style.card}`}
                                    >
                                        <img
                                            width="35px"
                                            src={IconeCifrao}
                                            alt="Ícone de população"
                                        />
                                        <div>
                                            <p className={Style.valorPopulacao}>
                                                1.437.366
                                            </p>
                                            <p className={Style.nomePopulacao}>
                                                transferências de <br />{" "}
                                                repasses federais
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className={Style.transparencia}>
                            dados do portal da transparência e SIOP
                        </p>
                    </div>
                </div>
                <div className={Style.boxMapaDireito}>
                    <select className={Style.selectCidade} name="" id="">
                        <option value="" disabled selected>
                            Escolha a Cidade
                        </option>
                        <option value="valor1">Opção 1</option>
                        <option value="valor2">Opção 2</option>
                        <option value="valor3">Opção 3</option>
                    </select>

                    <div className={Style.boxInformacoesDireito}>
                        <div className={Style.conjuntoCards}>
                            <div className={Style.linha1}>
                                <div
                                    className={`${Style.cardPopulacao} ${Style.card}`}
                                >
                                    <img
                                        width="60px"
                                        src={IconePopulacao}
                                        alt="Ícone de população"
                                    />
                                    <div>
                                        <p className={Style.valorPopulacao}>
                                            1.437.366
                                        </p>
                                        <p className={Style.nomePopulacao}>
                                            população
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={`${Style.cardPopulacao} ${Style.card}`}
                                >
                                    <img
                                        width="35px"
                                        src={IconeCifrao}
                                        alt="Ícone de população"
                                    />
                                    <div>
                                        <p className={Style.valorPopulacao}>
                                            1.437.366
                                        </p>
                                        <p className={Style.nomePopulacao}>
                                            emendas pagas
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={Style.linha2}>
                                <div
                                    className={`${Style.cardPopulacao} ${Style.card}`}
                                >
                                    <img
                                        width="35px"
                                        src={IconeCifrao}
                                        alt="Ícone de população"
                                    />
                                    <div>
                                        <p className={Style.valorPopulacao}>
                                            1.437.366
                                        </p>
                                        <p className={Style.nomePopulacao}>
                                            valor empenhado
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={`${Style.cardPopulacao} ${Style.card}`}
                                >
                                    <img
                                        width="35px"
                                        src={IconeCifrao}
                                        alt="Ícone de população"
                                    />
                                    <div>
                                        <p className={Style.valorPopulacao}>
                                            1.437.366
                                        </p>
                                        <p className={Style.nomePopulacao}>
                                            transferências de <br /> repasses
                                            federais
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className={Style.transparencia}>
                            dados do portal da transparência e SIOP
                        </p>
                    </div>
                </div>
            </section>

            <section>
                <Charts>
                    <Charts.box>
                        <Charts.header>
                            <header>
                                <h3>Evolução das emendas pagas</h3>
                            </header>
                            <ReactECharts
                                option={lineChartOption}
                                style={{ width: "100%", height: "350px" }}
                            />
                        </Charts.header>

                        <Charts.header>
                            <header>
                                <h3>Tipos de emendas</h3>
                            </header>
                        </Charts.header>

                        <Charts.echartsBox>
                            <ReactECharts
                                option={barChartOption}
                                style={{ width: "100%", height: "350px" }}
                            />
                        </Charts.echartsBox>
                    </Charts.box>

                    <Charts.box>
                        <Charts.header>
                            <header>
                                <h3>Transferências de repasses federais</h3>
                            </header>
                            <ReactECharts
                                option={lineChartOption}
                                style={{ width: "100%", height: "350px" }}
                            />
                        </Charts.header>

                        <Charts.header>
                            <header>
                                <h3>Origem das transferências</h3>
                            </header>
                        </Charts.header>

                        <Charts.echartsBox>
                            <ReactECharts
                                option={barChartOption}
                                style={{ width: "100%", height: "350px" }}
                            />
                        </Charts.echartsBox>
                    </Charts.box>
                </Charts>
            </section>
            <section className={Style.section_carrosel}>
                <div className={Style.header_carrosel}>
                    <p>
                        Saiba de onde outras cidades conseguiram o dinheiro
                        <br /> Outros comparativos entre municípios semelhantes
                        em
                    </p>
                    <div>
                        <button className={Style.active}>
                            renda per Capita
                        </button>
                        <button>IDH</button>
                        <button>faixa de habitantes</button>
                        <button>municípios próximos</button>
                    </div>
                </div>

                <Swiper
                    cssMode={true}
                    navigation={true}
                    pagination={{ clickable: true }}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]} // Adicione os módulos aqui
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className={Style.box_carrosel}>
                            <div className={Style.carrosel_titulo}>
                                <h3>Curitiba - PR</h3>
                                <div className={Style.rendacarrosel}>
                                    <p>R$ 33.000,00</p>
                                    <span>Renda per Capita/ano</span>
                                </div>
                            </div>
                            <Charts className={Style.box_charts}>
                                <Charts className={Style.tipo_emendas}>
                                    <header className={Style.header_emendas}>
                                        <h3>Tipos de emendas</h3>
                                    </header>

                                    <Charts.echartsBox>
                                        <ReactECharts
                                            option={barChartCarrosel}
                                            style={{
                                                width: "100%",
                                                height: "350px",
                                            }}
                                        />
                                    </Charts.echartsBox>
                                </Charts>
                            </Charts>
                            <Charts className={Style.box_charts}>
                                <Charts className={Style.tipo_emendas}>
                                    <header className={Style.header_emendas}>
                                        <h3>Tipos de emendas</h3>
                                    </header>

                                    <Charts.echartsBox>
                                        <ReactECharts
                                            option={barChartCarrosel}
                                            style={{
                                                width: "100%",
                                                height: "350px",
                                            }}
                                        />
                                    </Charts.echartsBox>
                                </Charts>
                            </Charts>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={Style.box_carrosel}>
                            <div className={Style.carrosel_titulo}>
                                <h3>Curitiba - PR</h3>
                                <div className={Style.rendacarrosel}>
                                    <p>R$ 33.000,00</p>
                                    <span>Renda per Capita/ano</span>
                                </div>
                            </div>
                            <Charts className={Style.box_charts}>
                                <Charts className={Style.tipo_emendas}>
                                    <header className={Style.header_emendas}>
                                        <h3>Tipos de emendas</h3>
                                    </header>

                                    <Charts.echartsBox>
                                        <ReactECharts
                                            option={barChartCarrosel}
                                            style={{
                                                width: "100%",
                                                height: "350px",
                                            }}
                                        />
                                    </Charts.echartsBox>
                                </Charts>
                            </Charts>
                            <Charts className={Style.box_charts}>
                                <Charts className={Style.tipo_emendas}>
                                    <header className={Style.header_emendas}>
                                        <h3>Tipos de emendas</h3>
                                    </header>

                                    <Charts.echartsBox>
                                        <ReactECharts
                                            option={barChartCarrosel}
                                            style={{
                                                width: "100%",
                                                height: "350px",
                                            }}
                                        />
                                    </Charts.echartsBox>
                                </Charts>
                            </Charts>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </section>
        </>
    );
};

export default ComparativoEmendas;
