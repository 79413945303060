import styled, { css } from 'styled-components';

import breakpoints from '../../theme/breakpoints';
import { get } from '../../theme/utils/functions';
import breakpointsMedia from '../../theme/utils/breakpointsMedia';


export const MapaEstadoWrapper = styled.div`
  height: 100%;
  /* height: ${({ $isMobile }) => ($isMobile ? '100%' : '100vh')}; */
  margin-top: ${({ $isMobile }) => ($isMobile ? '-30px' : 'auto')};
  width: 100vw;
  display: flex;
  overflow: visible;
  align-items: center;
  justify-content: center;
  background-color: #97DDE9;
  z-index: 0;

  ${breakpointsMedia({
    // sm: css`
    //   height: 100vh;
    // `,
    lg: css`
      overflow: hidden;
    `,
  })}

  svg {
    /* max-width: 99vw;
    max-height: 99vh; */
    cursor: grab;
    stroke-width: 120;
    height: auto;
    stroke: #96abae;

    path {
      transition: fill-opacity 0.3s ease, stroke 0.3s ease;
    }

    path.desfocado:hover {
      fill-opacity: 1;
      stroke-opacity: 1;
      /* Outros estilos */
    }

    path:hover  {
      fill-opacity: 1;
      stroke-opacity: 1;
      stroke: #FFD700;
      /* transform: scale(1.025);
      transform-origin: center;
      transform-box: fill-box;
      transition: transform 0.1s ease; */
    }

    .selecionado {
      /* fill: #d3a923; */
      fill-opacity: 1;
      stroke-opacity: 1;
      stroke: #FFD700;
      stroke-width: 120;
      /* transform: scale(1.50);
      transform-origin: center;
      transform-box: fill-box;
      transition: transform 0.1s ease; */
    }

    .desfocado {
      fill-opacity: 0.25;
      stroke-opacity: 0.25;
    }

    path.partido-PT {
      fill: #ED2424;
    }
    path.partido-PL {
        fill: #1A237E;
    }
    path.partido-UNIAO {
        fill: #3550b1;
    }
    path.partido-PP {
        fill: #68c1ec;
    }
    path.partido-PSB {
        fill: #FCB814;
    }
    path.partido-PSDB {
        fill: #022CD0;
    }
    path.partido-MDB {
        fill: #55B14C;
    }
    /* path.partido-PATRIOTA { ---> OBS: 51 - PATRIOTA - PATRIOTA (extinto por fusão com o PTB, originando o PRD)
        fill: #4AA561;
    } */
    /* path.partido-PTB { ---> OBS: 14 - PTB - PARTIDO TRABALHISTA BRASILEIRO (extinto por fusão com o PATRIOTA, originando o PRD)
        fill: #006400;
    } */
    path.partido-REPUBLICANOS {
        fill: #5c9dc4;
    }
    path.partido-PODE {
        fill: #578CC9;
    }
    path.partido-PSD {
        fill: #4040ff;
    }
    path.partido-AVANTE {
        fill: #D27901;
    }
    path.partido-CIDADANIA {
        fill: #CA0088;
    }
    path.partido-PSC {
        fill: #006F41;
    }
    path.partido-PROS {
        fill: #FFB465;
    }
    path.partido-DC {
        fill: #a7cc4c;
    }
    path.partido-PMN {
        fill: #E15B5B;
    }
    path.partido-PCDOB {
        fill: #bf0a0a;
    }
    path.partido-SOLIDARIEDADE {
        fill: #ff9d00;
    }
    path.partido-REDE {
        fill: #379E8D;
    }
    path.partido-PV {
        fill: #00A652;
    }
    path.partido-PDT {
        fill: #E45F02;
    }
    path.partido-NOVO {
        fill: #F17021;
    }
    path.partido-PSOL {
        fill: #42005b;
    }
    path.partido-PRTB {
        fill: #154b95;
    }
    path.partido-AGIR {
        fill: #2571C9;
    }
    path.partido-PMB {
        fill: #6195BC;
    }
    path.partido-PRD {
        fill: #1b3f7b;
    }
    path.partido-PCdoB {
        fill: #bf0a0a;
    }
    path.partido-MOBILIZA {
        fill: #E15B5B;
    }
    path.municipio-vai-para-segundo-turno {
      fill: #757373;
    } 

    path.sem-ganhador-e-sem-segundo-turno {
      fill: #d0d0d0;
    }

    path.ocultar--filtragem-por-partido {
        fill: #000; 
    } 

  &.mapa-al,
  &.mapa-pb,
  &.mapa-rj,
  &.mapa-rn,
  &.mapa-se {
    svg {
      stroke-width: 50;

      .selecionado {
        stroke-width: 50;
      }
    }
  }

  &.mapa-al,
  &.mapa-sc {
    svg {
      stroke-width: 80;

      .selecionado {
        stroke-width: 80;
      }
    }
  }
`;

export const MapaMunicipioWrapper = styled.div`
  height: 100%;
  /* height: ${({ $isMobile }) => ($isMobile ? '100%' : '100vh')}; */
  margin-top: ${({ $isMobile }) => ($isMobile ? '-30px' : 'auto')};
  width: 100vw;
  display: flex;
  overflow: visible;
  align-items: center;
  justify-content: center;
  background-color: #97DDE9;
  z-index: 0;
  @media screen and (min-width: ${breakpoints.sm}px) {
    height: 100vh;
  }

  svg {
    /* max-width: 99vw;
    max-height: 99vh; */
    cursor: grab;
    stroke-width: 1;
    /* height: auto; */
    stroke: #96abae;

    path {
      transition: fill-opacity 0.3s ease, stroke 0.3s ease;
    }

    g:focus {
      outline: none;
      border: none;
    }

    .desfocado:hover path {
      fill-opacity: 1;
      stroke-opacity: 1;
      /* Outros estilos */
    }

    path:hover  {
      fill-opacity: 1;
      stroke-opacity: 1;
      stroke: #FFD700 !important;;
      /* transform: scale(1.025);
      transform-origin: center;
      transform-box: fill-box;
      transition: transform 0.1s ease; */
    }

    .selecionado path{
      /* fill: #d3a923; */
      fill-opacity: 1;
      stroke-opacity: 1;
      stroke: #FFD700 !important;
      stroke-width: 1.1;
      /* transform: scale(1.50);
      transform-origin: center;
      transform-box: fill-box;
      transition: transform 0.1s ease; */
    }

    .desfocado path{
      fill-opacity: 0.25;
      stroke-opacity: 0.25;
    }

    .partido-PT path{
      fill: #ED2424;
    }
    .partido-PL path{
        fill: #1A237E;
    }
    .partido-UNIAO path{
        fill: #3550b1;
    }
    .partido-PP path{
        fill: #68c1ec;
    }
    .partido-PSB path{
        fill: #FCB814;
    }
    .partido-PSDB path{
        fill: #022CD0;
    }
    .partido-MDB path{
        fill: #55B14C;
    }
    /* .partido-PATRIOTA path{ ---> OBS: 51 - PATRIOTA - PATRIOTA (extinto por fusão com o PTB, originando o PRD)
        fill: #4AA561;
    } */
    /* .partido-PTB path{ ---> OBS: 14 - PTB - PARTIDO TRABALHISTA BRASILEIRO (extinto por fusão com o PATRIOTA, originando o PRD)
        fill: #006400;
    } */
    .partido-REPUBLICANOS path{
        fill: #5c9dc4;
    }
    .partido-PODE path{
        fill: #578CC9;
    }
    .partido-PSD path{
        fill: #4040ff;
    }
    .partido-AVANTE path{
        fill: #D27901;
    }
    .partido-CIDADANIA path{
        fill: #CA0088;
    }
    .partido-PSC path{
        fill: #006F41;
    }
    .partido-PROS path{
        fill: #FFB465;
    }
    .partido-DC path{
        fill: #a7cc4c;
    }
    .partido-PMN path{
        fill: #E15B5B;
    }
    .partido-PCDOB path{
        fill: #bf0a0a;
    }
    .partido-SOLIDARIEDADE path{
        fill: #ff9d00;
    }
    .partido-REDE path{
        fill: #379E8D;
    }
    .partido-PV path{
        fill: #00A652;
    }
    .partido-PDT path{
        fill: #E45F02;
    }
    .partido-NOVO path{
        fill: #F17021;
    }
    .partido-PSOL path{
        fill: #42005b;
    }
    .partido-PRTB path{
        fill: #154b95;
    }
    .partido-AGIR path{
        fill: #2571C9;
    }
    .partido-PMB path{
        fill: #6195BC;
    }
    .partido-PRD path{
        fill: #1b3f7b;
    }
    .partido-PCdoB path{
        fill: #bf0a0a;
    }
    .partido-MOBILIZA path{
        fill: #E15B5B;
    }
  }
`;

export const MapaDragWrapper = styled.div`
  height: 100%;
  /* height: ${({ $isMobile }) => ($isMobile ? '100%' : '80vh')}; */
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;

  &.hidden {
    visibility: hidden;
  }
  /* 
  @media screen and (min-width: ${breakpoints.sm}px) {
    height: 80vh;
  } */

  .svgEstados {
    transition: all 0.1s ease 0s;
    fill: #BAD6DB;
  }
`;
